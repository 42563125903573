/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core/';
import { IoFastFoodOutline } from 'react-icons/io5';
import { FiHome, FiShoppingCart } from 'react-icons/fi';
import { HiOutlineCurrencyDollar, HiOutlineLogout } from 'react-icons/hi';
import SpecificApiLocalClient from 'services/api';
import { toast } from 'react-toastify';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { FaConciergeBell } from 'react-icons/fa';
import { BsList } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import { useCart } from 'hooks/useCart';
import Modal from 'components/Modal';
import { useUserTelephone } from 'hooks/useUserTelephone';
import { getStorage, setStorage } from 'services/storage';
import { theme } from '../../App';
import { Container, ButtonCart, NotificationCart, CardModal } from './styles';

function Footer() {
  const { currentRoute, updateRoute } = useRoutes();
  const { cart, setCart } = useCart();
  const { setAccepted } = useUserTelephone();

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string,
  ) => {
    updateRoute(newValue);
  };

  const [waiterModal, setwaiterModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const history = useHistory();
  const { companyData } = useInformationsClient();
  const api = SpecificApiLocalClient();

  const deleteAddressStorage = () => {
    let storageAddress = JSON.parse(getStorage('address_list'));

    storageAddress = storageAddress.filter(
      x => x.type === 'retirada' || x.type === 'consumir',
    );
    setStorage('address_list', storageAddress);
  };

  const handleCallWaiter = useCallback(() => {
    let query: {
      _parameters: { NR_CONTA: number; DS_LOCAL: string }[];
    };
    if (companyData.command !== 0) {
      query = {
        _parameters: [
          {
            NR_CONTA: companyData.command,
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    } else if (companyData.cellPhone !== '') {
      query = {
        _parameters: [
          {
            NR_CONTA: Number(companyData.cellPhone),
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    } else {
      query = {
        _parameters: [
          {
            NR_CONTA: companyData.table,
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    }
    api
      .post(
        `/%22fprg_registraChamadoAtendenteJSON%22/${companyData.companyCode}`,
        query,
      )
      .then(() => {
        toast.success('Notificação enviada com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao realizar chamado!');
      });
  }, [api, companyData]);

  const waiterControll = true;

  return companyData.setMenu === 'S' && companyData.setMenuTable === 0 ? (
    companyData.setNotification === 'S' ? (
      <CardModal>
        <div id="modalContent">
          <Modal
            visible={waiterModal}
            setVisible={setwaiterModal}
            maxHeight={30}
            maxWidth={20}
          >
            <h2 className="text-modal-waiter">Deseja chamar o atendente?</h2>
            <div className="buttons-modal-waiter">
              <button
                type="button"
                className="button-outline"
                onClick={() => {
                  setwaiterModal(false);
                  handleCallWaiter();
                  updateRoute('/');
                  history.push('/');
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="button-filled"
                onClick={() => {
                  setwaiterModal(false);
                  updateRoute('/');
                  history.push('/');
                }}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            visible={exitModal}
            setVisible={setExitModal}
            maxHeight={30}
            maxWidth={20}
          >
            <h2 className="text-modal-waiter">Deseja sair da plataforma?</h2>
            <div className="buttons-modal-waiter">
              <button
                type="button"
                className="button-outline"
                onClick={() => {
                  setExitModal(false);
                  deleteAddressStorage();
                  localStorage.removeItem('clientInfo');
                  setStorage('lastCategory', 0);
                  setStorage('cart', []);
                  setCart([]);
                  setStorage('deliveryPhone', '');
                  setAccepted(false);
                  updateRoute('/');
                  history.push('/');
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="button-filled"
                onClick={() => {
                  setExitModal(false);
                  updateRoute('/');
                  history.push('/');
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </div>
      </CardModal>
    ) : null
  ) : (
    <CardModal>
      <div id="modalContent">
        <Modal
          visible={waiterModal}
          setVisible={setwaiterModal}
          maxHeight={30}
          maxWidth={20}
        >
          <h2 className="text-modal-waiter">Deseja chamar o atendente?</h2>
          <div className="buttons-modal-waiter">
            <button
              type="button"
              className="button-outline"
              onClick={() => {
                setwaiterModal(false);
                handleCallWaiter();
                updateRoute('/');
                history.push('/');
              }}
            >
              Sim
            </button>
            <button
              type="button"
              className="button-filled"
              onClick={() => {
                setwaiterModal(false);
                updateRoute('/');
                history.push('/');
              }}
            >
              Não
            </button>
          </div>
        </Modal>

        <Modal
          visible={exitModal}
          setVisible={setExitModal}
          maxHeight={30}
          maxWidth={20}
        >
          <h2 className="text-modal-waiter">Deseja sair da plataforma?</h2>
          <div className="buttons-modal-waiter">
            <button
              type="button"
              className="button-outline"
              onClick={() => {
                setExitModal(false);
                deleteAddressStorage();
                localStorage.removeItem('clientInfo');
                setStorage('lastCategory', 0);
                setStorage('cart', []);
                setCart([]);
                setStorage('deliveryPhone', '');
                setAccepted(false);
                updateRoute('/');
                history.push('/');
              }}
            >
              Sim
            </button>
            <button
              type="button"
              className="button-filled"
              onClick={() => {
                setExitModal(false);
                updateRoute('/');
                history.push('/');
              }}
            >
              Não
            </button>
          </div>
        </Modal>
      </div>
    </CardModal>
  );
}

export default Footer;
