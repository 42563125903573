/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ComplementsProps, TasteComplementsProps, TastePost } from 'models';
import { setStorage } from 'services/storage';

interface TasteComplementsProviderProps {
  children: ReactNode;
}

interface TasteComplementsContextData {
  tasteComplements: TasteComplementsProps[];
  setTasteComplements: React.Dispatch<
    React.SetStateAction<TasteComplementsProps[]>
  >;
  selectTasteCheckboxs: (
    checkbox: ComplementsProps,
    type: 'add' | 'rem',
  ) => void;
  selectTasteUpdate: (checkbox: TastePost) => void;
  clickTasteCounters: (complement: ComplementsProps, count: number) => void;
  clearTasteComplements: () => void;
}

const TasteComplementsContext = createContext<TasteComplementsContextData>(
  {} as TasteComplementsContextData,
);

export function TasteComplementsProvider({
  children,
}: TasteComplementsProviderProps) {
  const [tasteComplements, setTasteComplements] = useState<
    TasteComplementsProps[]
  >([]);

  const selectTasteCheckboxs = useCallback(
    (checkbox: ComplementsProps, type: 'add' | 'rem') => {
      let selectedComplement;
      if (
        tasteComplements.some(
          index =>
            index.ID_DM026 === checkbox.ID_DM026 &&
            index.ID_DM004 === checkbox.ID_DM004,
        )
      ) {
        const filteredComplements = tasteComplements.filter(
          index => index.ID_DM026 !== checkbox.ID_DM026,
        );
        selectedComplement = filteredComplements;
        setTasteComplements(filteredComplements);
      } else {
        checkbox.cd_item_complemento = checkbox.CD_ITEM_COMPLEMENTO;
        checkbox.ds_item = checkbox.DS_ITEM;
        checkbox.id = checkbox.ID_DM026;
        checkbox.isCheckBox = true;
        checkbox.isChecked = true;
        checkbox.priceFormart = '';
        checkbox.remover = type !== 'add';
        checkbox.qtd = 1;
        checkbox.title = checkbox.DS_ITEM;
        checkbox.priceFormated = '';
        checkbox.subtotal = '';
        selectedComplement = [...tasteComplements, checkbox];
        // @ts-ignore
        setTasteComplements([...tasteComplements, checkbox]);
      }
      setStorage('tasteComplements', selectedComplement);
    },
    [tasteComplements],
  );

  const selectTasteUpdate = useCallback(
    (checkbox: TastePost) => {
      const filteredTaste = tasteComplements.filter(
        index => index.ID_DM004 !== checkbox.cd_item_sabor,
      );
      setTasteComplements(filteredTaste);
      setStorage('tasteComplements', filteredTaste);
    },
    [tasteComplements],
  );

  const clickTasteCounters = useCallback(
    (complement: ComplementsProps, count: number) => {
      let clickedComplement;
      if (count > 0) {
        if (
          tasteComplements.some(
            index =>
              index.ID_DM026 === complement.ID_DM026 &&
              index.ID_DM004 === complement.ID_DM004,
          )
        ) {
          const complementExists = tasteComplements.find(
            complementFind => complementFind.ID_DM026 === complement.ID_DM026,
          );
          if (complementExists) {
            complementExists.qtd = count;
          }
          setTasteComplements([...tasteComplements]);
          clickedComplement = tasteComplements;
        } else {
          // add new complement
          complement.cd_item_complemento = complement.CD_ITEM_COMPLEMENTO;
          complement.ds_item = complement.DS_ITEM;
          complement.id = complement.ID_DM026;
          complement.isCheckBox = true;
          complement.isChecked = true;
          complement.priceFormart = '';
          complement.remover = false;
          complement.qtd = count;
          complement.title = complement.DS_ITEM;
          complement.priceFormated = '';
          complement.subtotal = '';
          // @ts-ignore
          setTasteComplements([...tasteComplements, complement]);
          clickedComplement = [...tasteComplements, complement];
        }
      } else {
        const filteredComplements = tasteComplements.filter(
          index => index.ID_DM026 !== complement.ID_DM026,
        );
        clickedComplement = filteredComplements;
        setTasteComplements(filteredComplements);
      }
      setStorage('tasteComplements', clickedComplement);
    },
    [tasteComplements],
  );

  const clearTasteComplements = useCallback(() => {
    setTasteComplements([]);
    setStorage('tasteComplements', []);
  }, []);

  const value = useMemo(
    () => ({
      tasteComplements,
      setTasteComplements,
      selectTasteCheckboxs,
      selectTasteUpdate,
      clickTasteCounters,
      clearTasteComplements,
    }),
    [
      tasteComplements,
      setTasteComplements,
      selectTasteCheckboxs,
      selectTasteUpdate,
      clickTasteCounters,
      clearTasteComplements,
    ],
  );

  return (
    <TasteComplementsContext.Provider value={value}>
      {children}
    </TasteComplementsContext.Provider>
  );
}

export const useTasteComplements = () => {
  const context = useContext(TasteComplementsContext);

  return context;
};
