/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface InformationsClientProviderProps {
  children: ReactNode;
}

interface InformationsClientContextData {
  companyData: {
    companyCode: number;
    desClient: string;
    table: number;
    command: number;
    guestCheckPad: number;
    tip: boolean;
    mandatoryTipTax: boolean;
    cellPhone: string;
    lat: string;
    lon: string;
    radius: number;
    ngrok: string;
    attendantCode: number;
    printerCode: number;
    setCustomer: string;
    setPhone: string;
    setLocation: string;
    setBanner: string;
    setGroups: string;
    setBlockConference: string;
    setPartnerPassword: string;
    setDelivery: string;
    setDeliveryTable: number;
    setNotification: string;
    setMenu: string;
    setMenuTable: number;
    setAutoConference: string;
    setAutoConferenceTable: number;
    setPriceItemTaste: string;
    setGetLocalDelivery: string;
    setDeliveryAddress: string;
    setWhatsAppNumber: string;
    setWhatsAppText: string;
  };

  setCompanyData: React.Dispatch<
    React.SetStateAction<{
      companyCode: number;
      desClient: string;
      table: number;
      command: number;
      guestCheckPad: number;
      tip: boolean;
      mandatoryTipTax: boolean;
      cellPhone: string;
      lat: string;
      lon: string;
      radius: number;
      ngrok: string;
      attendantCode: number;
      printerCode: number;
      setCustomer: string;
      setPhone: string;
      setLocation: string;
      setBanner: string;
      setGroups: string;
      setPartnerPassword: string;
      setBlockConference: string;
      setDelivery: string;
      setDeliveryTable: number;
      setNotification: string;
      setMenu: string;
      setMenuTable: number;
      setAutoConference: string;
      setAutoConferenceTable: number;
      setPriceItemTaste: string;
      setGetLocalDelivery: string;
      setDeliveryAddress: string;
      setWhatsAppNumber: string;
      setWhatsAppText: string;
    }>
  >;
}

const InformationsClientContext = createContext<InformationsClientContextData>(
  {} as InformationsClientContextData,
);

export function InformationsClientProvider({
  children,
}: InformationsClientProviderProps) {
  const [companyData, setCompanyData] = useState({
    companyCode: 0,
    desClient: '',
    table: 0,
    command: 0,
    guestCheckPad: 0,
    tip: true,
    mandatoryTipTax: true,
    cellPhone: '',
    lat: '0',
    lon: '0',
    radius: 0,
    ngrok: '',
    attendantCode: 0,
    printerCode: 0,
    setCustomer: '',
    setPhone: '',
    setLocation: '',
    setBanner: '',
    setGroups: '',
    setBlockConference: '',
    setPartnerPassword: '',
    setDelivery: '',
    setDeliveryTable: 0,
    setNotification: '',
    setMenu: '',
    setMenuTable: 0,
    setAutoConference: '',
    setAutoConferenceTable: 0,
    setPriceItemTaste: '',
    setGetLocalDelivery: '',
    setDeliveryAddress: '',
    setWhatsAppNumber: '',
    setWhatsAppText: '',
  });

  const value = useMemo(
    () => ({ companyData, setCompanyData }),
    [companyData, setCompanyData],
  );

  return (
    <InformationsClientContext.Provider value={value}>
      {children}
    </InformationsClientContext.Provider>
  );
}

export const useInformationsClient = () => {
  const context = useContext(InformationsClientContext);

  return context;
};
