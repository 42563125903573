import React, { HTMLProps, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { theme } from '../../App';

import { Container, PopupInner } from './styles';

interface Props extends HTMLProps<HTMLDivElement> {
  visible: boolean;
  setVisible: (value: boolean) => void;
  maxWidth: number;
  maxHeight: number;
  // eslint-disable-next-line react/require-default-props
  showExit?: boolean;
}

function Modal({
  visible,
  setVisible,
  maxWidth,
  maxHeight,
  showExit = true,
  children,
}) {
  const [hide, setHide] = useState(false);

  return visible ? (
    <Container>
      <PopupInner hide={hide} maxWidth={maxWidth} maxHeight={maxHeight}>
        {showExit && (
          <button
            type="button"
            id="button-exit"
            onClick={() => {
              setHide(true);

              setTimeout(() => {
                setVisible(false);
                setHide(false);
              }, 500);
            }}
          >
            <MdClose size={50} color={theme.palette.primary.main} />
          </button>
        )}
        <div id="align-children">{children}</div>
      </PopupInner>
    </Container>
  ) : null;
}

export default Modal;
