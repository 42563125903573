/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ComplementsProps, TastePost } from 'models';
import { setStorage } from 'services/storage';

interface CommentProviderProps {
  children: ReactNode;
}

interface CommentContextData {
  commentValue: ComplementsProps;
  setComment: React.Dispatch<React.SetStateAction<ComplementsProps>>;
  setCommentValue: (commentStr: string, id_dm004: number) => void;
}

const CommentContext = createContext<CommentContextData>(
  {} as CommentContextData,
);

export function CommentProvider({ children }: CommentProviderProps) {
  const [commentValue, setComment] = useState<ComplementsProps>();

  const setCommentValue = useCallback(
    (commentStr: string, id_dm004: number) => {
      const complementComment: ComplementsProps = {
        CD_ITEM: 0,
        CD_ITEM_COMPLEMENTO: 0,
        DS_ITEM: commentStr,
        ID_DM004: id_dm004,
        ID_DM026: 0,
        IN_ADICIONAL_REMOCAO: '',
        SQ_ORDEM_WEB: 0,
        IN_PADRAO: '',
        IN_SOLICITA_QUANTIDADE: '',
        QT_COMPLEMENTO: 0,
        QT_MAXIMA: 0,
        VL_VENDA: 0,
        cd_item_complemento: 0,
        ds_item: commentStr,
        id: 0,
        isCheckBox: true,
        isChecked: true,
        priceFormart: '',
        priceFormated: '',
        qtd: 0,
        remover: false,
        subtotal: '',
        title: commentStr,
        NR_GRUPO_QUANTIDADE: 0,
        QT_GRUPO_QUANTIDADE_COMBO: 0,
        NR_GRUPO_QUANTIDADE_MIN: 0,
        NR_GRUPO_QUANTIDADE_MAX: 0,
        ID_DM202: 0,
        DS_GRUPO_ESCOLHA: '',
        SQ_GRUPO_ESCOLHA: 0,
        DS_DESCRICAO_PRODUTO: '',
      };
      setComment(complementComment);
      setStorage('comment', complementComment);
    },
    [],
  );

  const value = useMemo(
    () => ({
      commentValue,
      setComment,
      setCommentValue,
    }),
    [commentValue, setComment, setCommentValue],
  );

  return (
    <CommentContext.Provider value={value}>{children}</CommentContext.Provider>
  );
}

export const useComment = () => {
  const context = useContext(CommentContext);
  return context;
};
