import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

:root {
  --primary-color: #F47321;
  --secondary-color: #FDF7F7;
  /* --price-color: #2A7925; */
  --price-color: #1CA719;
  --line-color: #DEDEDE;

  --primary-color-hover: #E36E24;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;

  /* Tamanho */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Barra */
  ::-webkit-scrollbar-thumb {
    background: var(--line-color);
    border-radius: 1rem;
  }

  /* Fundo */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--line-color);
    border-radius: 1rem;
  }

  /* Seleções */
  ::selection {
    background: var(--primary-color);
    color: #fff;
  }
}

// Default Size Font - 16px
html {
  @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
  }
  @media (max-width: 720px) {
      font-size: 87.5%; // 14px
  }
}

html, body, #root {
  height: 100vh;
  width: 100vw;
}

body, h1, h2, h3, button, span, a, p {
  font-family: 'Roboto', sans-serif;
}

button {
  cursor: pointer;
  outline: 0;
}

.button-filled {
  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);
  margin-bottom: 1rem;
  color: #fff;
}

.button-outline {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--secondary-color);
  color: #6c6c6c;
}

.title-product {
  font-weight: 400;
  color: #2A292B;
  padding: 0.5rem 1rem 0;
}

.description-product {
  font-weight: 200;
  color: #403E44;
  padding: 1rem;
}

.value-product {
  font-weight: 500;
  color: var(--price-color);
  padding: 0 1rem 0.5rem;
}

.increase-size-check {
  svg {
    width: 2rem;
    height: 2rem;
  }
}

`;
