import React from 'react';
import CardInformationsProduct from 'components/CarInformationsProduct';
import { ProductsProps } from 'models';
import { Container } from './styles';
import { useInformationsClient } from '../../hooks/useInformationsClient';

interface ProductHorizontalProps {
  product: ProductsProps;
}

interface ImageFoodProps {
  companyCode: string;
  productId: string;
}

function ImageFood({ companyCode, productId }: ImageFoodProps) {
  const day = new Date().getDate();
  const url_product_img = `https://datamais.com.br/img-autoatendimento/${companyCode}/I${productId}.jpg?${day}`;
  return <img src={url_product_img} alt="Produto" />;
}

function ProductHorizontal({ product }: ProductHorizontalProps) {
  const { companyData } = useInformationsClient();

  return (
    <Container>
      <CardInformationsProduct height={100} product={product} />
      <ImageFood
        companyCode={companyData?.companyCode?.toString()}
        productId={product?.ID_DM004?.toString()}
      />
    </Container>
  );
}

export default ProductHorizontal;
