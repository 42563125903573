import React, { useState } from 'react';
import { formatPixKey } from 'utils/formatPixKey';
import { optionPaymentProps } from 'models/paymentTypeOptions';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { FaRegCopy } from 'react-icons/fa';
import { capitalizeFirst } from 'utils/formatString';
import { theme } from '../../../../App';
import { Container } from './styles';

interface CopyPixKeyToClipboardProps {
  paymentType: optionPaymentProps;
}

export function CopyPixKeyToClipboard({ paymentType }) {
  const [copiedText, copy] = useCopyToClipboard();
  const [hasCopiedText, setHasCopiedText] = useState(false);
  const formattedPixKey = formatPixKey(
    paymentType.DS_CHAVEPIX || '00000000000',
    paymentType.IN_TIPO_CHAVEPIX || 'A',
    // 'C',
  );

  const formattedBeneficiary = capitalizeFirst(
    paymentType.NM_BENEFICIARIOPIX || '',
  );
  const formattedCity = capitalizeFirst(paymentType.NM_CIDADEPIX || '');

  function handleCopy() {
    copy(formattedPixKey)
      .then(() => {
        setHasCopiedText(true);
      })
      .catch(e => console.error(e))
      .finally(() => setTimeout(() => setHasCopiedText(false), 2000));
  }

  return (
    <Container theme={theme}>
      <div className="pix-line">
        {hasCopiedText && <span className="copy-message">Copiado!</span>}
        <h2>{formattedPixKey}</h2>
        <button type="button" className="copy-button" onClick={handleCopy}>
          <FaRegCopy color={theme.palette.primary.main} />
        </button>
      </div>
      <div className="beneficiary-data">
        <p className="beneficiary-description">{formattedBeneficiary}</p>
        <p className="beneficiary-address">{formattedCity}</p>
      </div>
    </Container>
  );
}
