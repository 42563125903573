/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { getStorage, setStorage } from 'services/storage';
import { useComplements } from 'hooks/useComplements';
import { useTastes } from 'hooks/useTastes';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { checkPromotion } from 'utils/checkPromotion';

import {
  ProductsCartProps,
  ComplementsProps,
  ProductsProps,
  TasteComplementsProps,
  TastePost,
} from 'models';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { dateUnnixTime } from 'utils/dateUnixtime';
import { useTasteComplements } from './useTasteComplements';

interface CartProviderProps {
  children: ReactNode;
}

interface CartContextData {
  cart: ProductsCartProps[];
  setCart: React.Dispatch<React.SetStateAction<ProductsCartProps[]>>;
  renderProducts: () => void;
  handleAddProductInCart: (product: ProductsProps, counter: number) => void;
  handleDeleteProductInCart: (id: number) => void;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

export function CartProvider({ children }: CartProviderProps) {
  const { complements, setComplements } = useComplements();
  const { tastes, setTastes } = useTastes();
  const { tasteComplements, setTasteComplements } = useTasteComplements();
  const { companyData } = useInformationsClient();

  const [cart, setCart] = useState<ProductsCartProps[]>([]);

  const renderProducts = useCallback(() => {
    const listProducts = getStorage('cart');
    if (listProducts) {
      const stringfiedProducts: ProductsCartProps[] = JSON.parse(listProducts);
      setCart(stringfiedProducts);
    }
  }, []);

  const handleAddProductInCart = useCallback(
    (product: ProductsProps, counter: number) => {
      const listProducts = getStorage('cart');
      const commentCart: any = JSON.parse(getStorage('comment'));
      const tastesComplementCart: TasteComplementsProps[] = JSON.parse(
        getStorage('tasteComplements'),
      );

      let vl_venda_item = 0;
      let subtotal = 0;

      const totalValueComplements = complements.reduce(
        (accum: number, curr: ComplementsProps) =>
          accum + Number(curr.VL_VENDA),
        0,
      );

      const totalValueTasteComplements = tasteComplements.reduce(
        (accum: number, curr: TasteComplementsProps) =>
          accum + Number(curr.VL_VENDA),
        0,
      );

      const tmp_complements = complements.map(item => {
        const new_item = item;
        if (new_item.qtd > 0) {
          new_item.ds_item = `(${item.qtd}) ${item.ds_item}`;
          new_item.DS_ITEM = `(${item.qtd}) ${item.DS_ITEM}`;
          new_item.title = `(${item.qtd}) ${item.title}`;
        }
        return new_item;
      });
      let newcomplements: ComplementsProps[] = [];
      if (JSON.stringify(commentCart) === '{}') {
        newcomplements = [...tmp_complements];
      } else {
        newcomplements = [...tmp_complements, commentCart];
      }

      let tastesWithComplements = [];
      tastes.forEach(val => {
        const t = tastesComplementCart.filter(complementVal => {
          if (complementVal.ID_DM004 === val.cd_item_sabor) {
            return true;
          }
          return false;
        });
        const tmpComplement = val;
        // @ts-ignore
        tmpComplement.complementos = t;
        tastesWithComplements = [...tastesWithComplements, tmpComplement];
      });

      if (tastes.length > 0) {
        const totalValueTastes = tastes.reduce(
          (accum: number, curr: TastePost) =>
            accum + Number(curr.VL_ITEM_SABOR),
          0,
        );
        const tastesLength = tastes.length;
        let tasteSum = 0;
        tastes.forEach(e => {
          tasteSum += e.VL_ITEM_SABOR / tastesLength;
        });
        const totalPriceProduct = Number(
          (
            totalValueComplements +
            totalValueTasteComplements +
            tasteSum
          ).toFixed(2),
        );
        vl_venda_item = totalPriceProduct;
        subtotal = vl_venda_item * counter;
        // TODO: Promotion on tastes
      } else {
        const totalPriceProduct = Number(
          (
            totalValueComplements +
            totalValueTasteComplements +
            (product.VL_VENDA ?? 0)
          ).toFixed(2),
        );
        vl_venda_item = totalPriceProduct;
        subtotal = totalPriceProduct * counter;
        const [sale_promotion, sale_amount, promotion_value] =
          checkPromotion(product);
        if (sale_promotion && sale_amount) {
          const complements_value =
            totalValueComplements + totalValueTasteComplements;
          vl_venda_item = Number(
            (promotion_value + complements_value).toFixed(2),
          );
          subtotal = Number(
            ((promotion_value + complements_value) * counter).toFixed(2),
          );
        }
      }

      const {
        ID_DM003,
        ID_DM004,
        ID_DM206,
        CD_ITEM,
        DS_ITEM,
        AGRUPAMENTO,
        DS_ITEM_REDUZIDO,
        DS_DESCRICAO_PRODUTO,
        VL_VENDA,
        CD_BARRA,
        CD_UNIDADE_MEDIDA,
        IN_PESO,
        DS_COR,
        ID_DM070,
        IN_PREPARO,
        IN_DISPENSA_TAXA_SERVICO,
        QT_SABOR,
        ID_IAT,
        IN_FAVORITO,
        VL_MAXIMO_VENDA,
        IN_TIPO_CODIGO_BARRA,
        IN_GERAR_ITEM_SABOR,
        DT_PERIODO_DE,
        DT_PERIODO_ATE,
        HR_VALIDADE1_DE,
        HR_VALIDADE1_ATE,
        HR_VALIDADE2_DE,
        HR_VALIDADE2_ATE,
        PR_DESCONTO,
        VL_DESCONTO,
        VL_UNITARIO_PROMOCAO,
        QT_MINIMA,
        QT_MAXIMA,
        QT_DISPONIVEL,
        QT_CONSUMO,
      } = product;
      const newProduct: ProductsCartProps = {
        ID_DM206,
        ID_DM003,
        ID_DM004,
        CD_ITEM,
        DS_ITEM,
        AGRUPAMENTO,
        DS_ITEM_REDUZIDO,
        DS_DESCRICAO_PRODUTO,
        VL_VENDA,
        CD_BARRA,
        CD_UNIDADE_MEDIDA,
        IN_PESO,
        DS_COR,
        ID_DM070,
        IN_PREPARO,
        IN_DISPENSA_TAXA_SERVICO,
        QT_SABOR,
        ID_IAT,
        IN_FAVORITO,
        VL_MAXIMO_VENDA,
        IN_TIPO_CODIGO_BARRA,
        IN_GERAR_ITEM_SABOR,
        DT_PERIODO_DE,
        DT_PERIODO_ATE,
        HR_VALIDADE1_DE,
        HR_VALIDADE1_ATE,
        HR_VALIDADE2_DE,
        HR_VALIDADE2_ATE,
        PR_DESCONTO,
        VL_DESCONTO,
        VL_UNITARIO_PROMOCAO,
        QT_MINIMA,
        QT_MAXIMA,
        QT_DISPONIVEL,
        QT_CONSUMO,
        complements: newcomplements && newcomplements,
        id: 0,
        qtd: counter,
        priceFormart: VL_VENDA,
        cd: ID_DM004,
        priceFormated: VL_VENDA,
        subtotal,
        id_dm004: ID_DM004,
        ds_item: DS_ITEM,
        NrConta: companyData.command ? companyData.command : 0,
        NrLocal: String(companyData.table),
        NrFone: companyData.cellPhone,
        IdCliente: 0,
        qt_venda_item: counter,
        vl_venda_item,
        id_dm206: ID_DM206,
        complementos: newcomplements && newcomplements,
        sabores: tastesWithComplements && tastesWithComplements,
        timestamp: new Date().getTime(),
      };
      if (listProducts) {
        const parsedProducts: ProductsCartProps[] = JSON.parse(listProducts);
        // check item using timestamp as id to search and delete
        // Marco's legacy code
        // only to keep documented
        // const hasNewProduct = parsedProducts.filter(
        //   productFiltered => productFiltered.ID_DM004 === newProduct.ID_DM004,
        // );

        // if (hasNewProduct.length > 0) {
        //   toast.warn('Você já possui esse item no carrinho!');
        // }
        const newRegister = [...parsedProducts, newProduct];
        setStorage('cart', newRegister);
      } else {
        setStorage('cart', [newProduct]);
      }

      setComplements([]);
      setTastes([]);
      setTasteComplements([]);
      setStorage('complements', []);
      setStorage('tastes', []);
      setStorage('tasteComplements', []);
      setStorage('comment', {});
    },
    [
      complements,
      tastes,
      tasteComplements,
      companyData.command,
      companyData.table,
      companyData.cellPhone,
      setComplements,
      setTastes,
      setTasteComplements,
    ],
  );

  const handleDeleteProductInCart = useCallback(
    (id: number) => {
      const listProducts = getStorage('cart');
      if (listProducts) {
        const stringfiedProducts: ProductsCartProps[] =
          JSON.parse(listProducts);
        const newProducts = stringfiedProducts.filter(
          product => product.timestamp !== Number(id),
        );
        setCart(newProducts);
        setStorage('cart', newProducts);
        renderProducts();
      }
    },
    [renderProducts],
  );

  const value = useMemo(
    () => ({
      cart,
      setCart,
      renderProducts,
      handleDeleteProductInCart,
      handleAddProductInCart,
    }),
    [cart, renderProducts, handleDeleteProductInCart, handleAddProductInCart],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export const useCart = () => {
  const context = useContext(CartContext);

  return context;
};
