import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Home from 'pages/Home';
import Cart from 'pages/Cart';
import Profile from 'pages/Profile';
import Changes from 'pages/Changes';
import ProductDetail from 'pages/ProductDetail';
import LastOrders from 'pages/LastOrders';
import Command from 'pages/Command';
import Hash from 'pages/Hash';
import NewCommand from 'pages/NewCommand';

function Routes(): React.ReactElement {
  return (
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/products/:id" exact component={ProductDetail} />

      <Route path="/cart" exact component={Cart} />

      <Route path="/command" exact component={Command} />

      <Route path="/profile" exact component={Profile} />
      <Route path="/new_command" exact component={NewCommand} />
      <Route path="/changes" exact component={Changes} />
      <Route path="/profile/last-orders" exact component={LastOrders} />

      <Route
        path="/:hash"
        render={props => <Hash props={props.match.params.hash} />}
      />
    </Switch>
  );
}

export default Routes;
