import { Button } from '@material-ui/core';
import { ComplementsProps } from 'models';
import React, { HTMLProps, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import SpecificApiLocalClient from 'services/api';
import { useTasteComplements } from 'hooks/useTasteComplements';
import { theme } from '../../App';

import { Container, PopupInner } from './styles';

interface Props extends HTMLProps<HTMLDivElement> {
  visible: boolean;
  setVisible: (value: boolean) => void;
  idDM004: number;
  maxWidth: number;
  maxHeight: number;
  // eslint-disable-next-line react/require-default-props
  showExit?: boolean;
}

function Modal({
  visible,
  setVisible,
  idDM004,
  maxWidth,
  maxHeight,
  showExit = true,
  children,
}) {
  const [hide, setHide] = useState(false);
  const [complementsCurrentTaste, setComplementsCurrentTaste] = useState<
    ComplementsProps[]
  >([]);
  const api = SpecificApiLocalClient();
  const { tasteComplements } = useTasteComplements();

  const getTasteComplements = (cd_item_sabor: number) => {
    api
      .get(`/fprg_GetComplementosByProdutoJSON/${cd_item_sabor}/0/`)
      .then(response => {
        const complementsResponse: ComplementsProps[] = response.data.result[0];
        if (complementsResponse.length > 0) {
          setComplementsCurrentTaste(
            complementsResponse.filter(val => val.ID_DM202 > 0),
          );
        }
      })
      .catch(() => {
        toast.error('Erro no carregamento dos Complementos do Sabor');
      });
  };

  const nextTasteModal = () => {
    let tasteComplementsLocalStorage = [];
    try {
      tasteComplementsLocalStorage = JSON.parse(
        localStorage.getItem('tasteComplements'),
      );
      api
        .get(`/fprg_GetComplementosByProdutoJSON/${idDM004}/0/`)
        .then(response => {
          const complementsResponse: ComplementsProps[] =
            response.data.result[0].filter(val => val.ID_DM202 > 0);
          if (complementsResponse.length > 0) {
            if (tasteComplementsLocalStorage.length !== 0) {
              let listIdDM202 = [];
              complementsResponse.forEach(val => {
                listIdDM202.push({
                  ID_DM202: val.ID_DM202,
                  DS_GRUPO_ESCOLHA: val.DS_GRUPO_ESCOLHA,
                });
              });
              listIdDM202 = listIdDM202
                .filter(val => val.ID_DM202 !== 0)
                .filter(
                  (obj, pos, arr) =>
                    arr.map(mapobj => mapobj.ID_DM202).indexOf(obj.ID_DM202) ===
                    pos,
                );
              listIdDM202.forEach(val => {
                const val_length = tasteComplementsLocalStorage.filter(
                  c => c.ID_DM202 === val.ID_DM202,
                );
                if (val_length.length !== val.ID_DM202) {
                  toast.warning(
                    `Verifique o complemento obrigatório ${val.DS_GRUPO_ESCOLHA}`,
                  );
                }
              });
              setHide(true);
              setTimeout(() => {
                setVisible(false);
                setHide(false);
              }, 100);
            } else {
              setHide(true);
              setTimeout(() => {
                setVisible(false);
                setHide(false);
              }, 100);
              // TODO: get to return taste name using DM004
              toast.warning('Verifique os complementos obrigatórios do sabor');
            }
          } else {
            setHide(true);
            setTimeout(() => {
              setVisible(false);
              setHide(false);
            }, 100);
          }
        })
        .catch(() => {
          toast.error('Erro no carregamento dos Complementos do Sabor');
        });
    } catch (error) {
      console.log(error);
      setHide(true);
      setTimeout(() => {
        setVisible(false);
        setHide(false);
      }, 100);
    }
  };
  return visible ? (
    <Container>
      <PopupInner hide={hide} maxWidth={maxWidth} maxHeight={maxHeight}>
        {showExit && (
          <button
            type="button"
            id="button-exit"
            onClick={() => {
              nextTasteModal();
            }}
          >
            <MdClose size={50} color={theme.palette.primary.main} />
          </button>
        )}
        <div id="align-children">{children}</div>
        {showExit && (
          <Button
            aria-label="add-to-cart"
            id="buttonAdd"
            onClick={() => {
              nextTasteModal();
            }}
          >
            <p>Continuar</p>
          </Button>
        )}
      </PopupInner>
    </Container>
  ) : null;
}

export default Modal;
