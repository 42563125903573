import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useRoutes } from 'hooks/useRoutes';
import { IoIosArrowBack } from 'react-icons/io';
import Button from '@material-ui/core/Button';
import Lottie from 'react-lottie';

import SpecificApiLocalClient from 'services/api';
import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import { useComplements } from 'hooks/useComplements';
import { useComment } from 'hooks/useComments';
import { useCart } from 'hooks/useCart';
import { useTastes } from 'hooks/useTastes';
import { useTasteComplements } from 'hooks/useTasteComplements';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { checkPromotion } from 'utils/checkPromotion';

import InformationsProduct from 'components/InformationsProduct';
import Complements from 'components/Complements';
import Tastes from 'components/Tastes';

import AnimationLoading from 'assets/animations/loading.json';

import {
  ProductsProps,
  ComplementsProps,
  TastePost,
  TasteComplementsProps,
} from 'models';

import { theme } from '../../App';
import {
  Container,
  ContainerFooterAdd,
  ContainerContentProduct,
  ContainerCounter,
  ContainerLoading,
} from './styles';

function ProductDetail() {
  const { updateRoute } = useRoutes();
  const { complements, clearComplements } = useComplements();
  const { setCommentValue } = useComment();
  const { tastes, clearTastes } = useTastes();
  const { tasteComplements, clearTasteComplements } = useTasteComplements();
  const { handleAddProductInCart } = useCart();
  const { companyData } = useInformationsClient();
  const api = SpecificApiLocalClient();
  const [currentProduct, setCurrentProduct] = useState<ProductsProps>(
    {} as ProductsProps,
  );
  const [complementsCurrentProduct, setComplementsCurrentProduct] = useState<
    ComplementsProps[]
  >([]);

  const [tastesCurrentProductPost, setTastesCurrentProductPost] = useState<
    TastePost[]
  >([]);

  const [counter, setCounter] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [addCart, setAddCart] = useState(true);
  const [totalPriceProduct, setTotalPriceProduct] = useState(0);
  const [promotionalPrice, setPromotionalPrice] = useState(0);
  const [currentPromotionalPrice, setCurrentPromotionalPrice] = useState(0);
  const [promotional, setPromotional] = useState(false);

  const params: { id: string } = useParams();

  const history = useHistory();

  const paragraphRef = useRef(null);

  if (companyData.companyCode === 0) {
    updateRoute('/');
    history.push('/');
  }

  useEffect(() => {
    api
      .get(
        `/fprg_GetProdutoJSON_AA/${companyData.companyCode}/${params.id}/0/0/`,
      )
      .then(response => {
        setCurrentProduct(response.data.result[0][0]);
      })
      .catch(error => {
        if (Number(error.response.status) !== 200) {
          api
            .get(
              `/fprg_GetProdutoJSON/${companyData.companyCode}/${params.id}/0/0/`,
            )
            .then(response => {
              setCurrentProduct(response.data.result[0][0]);
            })
            .catch(() => {
              toast.error('Erro no carregamento dos Complementos do Produto');
            });
        } else {
          toast.error('Erro no carregamento dos Complementos do Produto');
          console.log(error.message);
        }
      });

    api
      .get(`/fprg_GetComplementosByProdutoJSON/${params.id}/0/`)
      .then(response => {
        setComplementsCurrentProduct(response.data.result[0]);
      })
      .catch(() => {
        toast.error('Erro no carregamento dos Complementos do Produto');
      });

    api
      .get(`/fprg_GetSaboresJSONByProdutoJSON/${params.id}/0/`)
      .then(response => {
        const resp = response.data.result[0];
        const post = resp.map(val => {
          return {
            id_dm089: val.ID_DM089,
            id_dm004: val.ID_DM004,
            cd_item_sabor: val.CD_ITEM_SABOR,
            id_dm004_sabor: val.CD_ITEM_SABOR,
            cd_item: val.CD_ITEM,
            ds_item: val.DS_ITEM,
            qt_item_sabor: val.QT_ITEM_SABOR,
            VL_ITEM_SABOR: val.VL_ITEM_SABOR,
            DS_DESCRICAO_PRODUTO: val.DS_DESCRICAO_PRODUTO,
            is_checked: true,
          };
        });
        setTastesCurrentProductPost(post);
      })
      .catch(() => {
        toast.error('Erro no carregamento dos Sabores do Produto');
      });
    // eslint-disable-next-line
  }, []);

  const handleAddProductToNewCart = useCallback(() => {
    let add_cart = true;

    if (
      currentProduct.VL_VENDA !== undefined &&
      currentProduct.VL_VENDA !== 0 &&
      counter > 0
    ) {
      if (currentProduct.QT_MINIMA > 0 && counter < currentProduct.QT_MINIMA) {
        if (currentProduct.QT_MINIMA === 1) {
          toast.error(`Quantidade mínima de ${currentProduct.QT_MINIMA} item`);
        } else {
          toast.error(`Quantidade mínima de ${currentProduct.QT_MINIMA} itens`);
        }
      } else {
        const minimalQuantity = complementsCurrentProduct.filter(
          val => val.NR_GRUPO_QUANTIDADE_MIN > 0,
        );

        const tmpMinimalQuantityIdDm202: number[] = minimalQuantity.map(
          val => val.ID_DM202,
        );
        const minimalQuantityIdDm202 = tmpMinimalQuantityIdDm202.filter(
          (value, index, array) => array.indexOf(value) === index,
        );
        minimalQuantityIdDm202.forEach(valDm202 => {
          const tmpComplement = complementsCurrentProduct.filter(
            val => val.ID_DM202 === valDm202,
          );
          const tmpCombo = tmpComplement
            .map(combo => combo.QT_GRUPO_QUANTIDADE_COMBO)
            .filter((value, index, array) => array.indexOf(value) === index);
          tmpCombo.forEach(v => {
            const tmpQt = complements.filter(
              v2 =>
                v2.QT_GRUPO_QUANTIDADE_COMBO === v && v2.ID_DM202 === valDm202,
            );
            const tmpComplementInfo = tmpComplement.filter(
              e => e.ID_DM202 === valDm202 && e.QT_GRUPO_QUANTIDADE_COMBO === v,
            );
            const qtMin = tmpComplementInfo[0].NR_GRUPO_QUANTIDADE_MIN;
            const qtMax = tmpComplementInfo[0].NR_GRUPO_QUANTIDADE_MAX;
            const sumQtCombo = tmpQt.reduce((acc, item) => {
              if (item.qtd !== undefined) {
                return acc + item.qtd;
              }
              return acc;
            }, 0);
            if (qtMin > 0 && sumQtCombo < qtMin) {
              add_cart = false;
              if (tmpComplementInfo[0].DS_GRUPO_ESCOLHA === '') {
                if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO > 1) {
                  toast.warn(
                    `Adicione no mímimo ${qtMin} de ${tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO}`,
                  );
                } else if (
                  tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO === 1
                ) {
                  toast.warn(`Adicione no mínimo 1 item!`);
                } else {
                  toast.warn(
                    `Adicione conforme a preferência no mínimo 1 item!`,
                  );
                }
              } else if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO > 1) {
                toast.warn(
                  `Adicione no mímimo ${qtMin} de ${tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO} ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              } else if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO === 1) {
                toast.warn(
                  `Adicione no mímimo 1 Item de ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              } else {
                toast.warn(
                  `Adicione no mímimo ${qtMin} de ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              }
            }
            if (qtMax > 0 && sumQtCombo > qtMax) {
              add_cart = false;
              if (tmpComplementInfo[0].DS_GRUPO_ESCOLHA === '') {
                if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO > 1) {
                  toast.warn(
                    `Adicione no máximo ${qtMax} de ${tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO}`,
                  );
                } else if (
                  tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO === 1
                ) {
                  toast.warn(`Adicione no máximo 1 item!`);
                } else {
                  toast.warn(
                    `Adicione conforme a preferência no máximo 1 item!`,
                  );
                }
              } else if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO > 1) {
                toast.warn(
                  `Adicione no máximo ${qtMax} de ${tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO} ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              } else if (tmpComplementInfo[0].QT_GRUPO_QUANTIDADE_COMBO === 1) {
                toast.warn(
                  `Adicione no máximo 1 Item de ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              } else {
                toast.warn(
                  `Adicione no máximo ${qtMax} de ${tmpComplementInfo[0].DS_GRUPO_ESCOLHA}`,
                );
              }
            }
          });
        });

        if (add_cart) {
          handleAddProductInCart(currentProduct, counter);
          updateRoute('/cart');
          history.push('/cart');
        }
      }
    }
  }, [
    complements,
    complementsCurrentProduct,
    counter,
    currentProduct,
    handleAddProductInCart,
    history,
    updateRoute,
  ]);

  useEffect(() => {
    if (currentProduct.DS_ITEM !== undefined) {
      setisLoading(false);
    }
  }, [currentProduct]);

  useEffect(() => {
    const totalValueComplements = complements.reduce(
      (accum: number, curr: ComplementsProps) =>
        accum + Number(curr.VL_VENDA * curr.qtd),
      0,
    );
    let tasteSum = 0;
    const tastesLength = tastes.length;

    if (tastesLength > 0) {
      tastes.forEach(e => {
        tasteSum += e.VL_ITEM_SABOR / tastesLength;
      });
    }
    const totalValueTastes = tastes.reduce(
      (accum: number, curr: TastePost) =>
        accum + Number(curr.VL_ITEM_SABOR * curr.qt_item_sabor),
      0,
    );
    const totalValueTastesComplements = tasteComplements.reduce(
      (accum: number, curr: TasteComplementsProps) =>
        accum + Number(curr.VL_VENDA * curr.qtd),
      0,
    );
    let totalPrice = 0;

    if (
      tastesCurrentProductPost.length > 0 &&
      companyData.setPriceItemTaste === 'S'
    ) {
      totalPrice =
        (totalValueComplements + totalValueTastesComplements + tasteSum) *
        counter;
    } else {
      totalPrice =
        (totalValueComplements +
          totalValueTastesComplements +
          (currentProduct?.VL_VENDA || 0)) *
        counter;
    }

    let totalPromotionalPrice = 0;
    const promotion_value = promotionalPrice;
    const complements_value =
      totalValueComplements + totalValueTastes + totalValueTastesComplements;
    totalPromotionalPrice = promotion_value + complements_value;
    totalPromotionalPrice *= counter;
    if (promotional) {
      setTotalPriceProduct(Number(totalPrice.toFixed(2)));
      setCurrentPromotionalPrice(Number(totalPromotionalPrice.toFixed(2)));
    } else {
      setCurrentPromotionalPrice(0);
      setTotalPriceProduct(Number(totalPrice.toFixed(2)));
    }
  }, [
    companyData.setPriceItemTaste,
    complements,
    counter,
    currentProduct,
    promotional,
    promotionalPrice,
    tasteComplements,
    tastes,
    tastesCurrentProductPost,
    totalPriceProduct,
  ]);

  const handleAddCount = () => {
    const add = counter + 1;
    if (
      currentProduct.QT_MAXIMA > 0 &&
      currentProduct.QT_MAXIMA === counter &&
      currentProduct.ID_DM206 > 0
    ) {
      toast.warning('Você atingiu a quantidade máxima deste item');
    } else if (
      currentProduct.QT_DISPONIVEL - currentProduct.QT_CONSUMO >= 1 &&
      currentProduct.QT_DISPONIVEL - currentProduct.QT_CONSUMO > counter &&
      currentProduct.ID_DM206 > 0
    ) {
      setCounter(add);
    } else if (
      currentProduct.QT_DISPONIVEL - currentProduct.QT_CONSUMO === 1 &&
      currentProduct.ID_DM206 > 0
    ) {
      toast.warning(`Somente uma unidade disponível!`);
    } else if (
      currentProduct.ID_DM206 > 0 &&
      currentProduct.QT_DISPONIVEL > 0
    ) {
      toast.warning(
        `Somente ${
          currentProduct.QT_DISPONIVEL - currentProduct.QT_CONSUMO
        } disponíveis!`,
      );
    } else {
      setCounter(add);
    }
  };

  const handleSubCount = () => {
    if (counter === 1) {
      setCounter(1);
    } else {
      setCounter(counter - 1);
    }
  };

  const day = new Date().getDate();
  const url_product_img = `https://datamais.com.br/img-autoatendimento/${companyData.companyCode}/I${params.id}.jpg?${day}`;

  const group_values = complementsCurrentProduct
    .map(item => {
      // NOTE: to keep older prjGerenciadorAutomacao
      if (item.QT_GRUPO_QUANTIDADE_COMBO === undefined) {
        return item.NR_GRUPO_QUANTIDADE;
      }
      return item.QT_GRUPO_QUANTIDADE_COMBO;
    })
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
    .sort();
  const add_complements = complementsCurrentProduct.filter(
    complement =>
      complement.IN_ADICIONAL_REMOCAO !== 'R' && complement.ID_DM202 === 0,
  );
  const remove_complements = complementsCurrentProduct.filter(
    complement =>
      complement.IN_ADICIONAL_REMOCAO === 'R' && complement.ID_DM202 === 0,
  );
  const mandatory_complements = complementsCurrentProduct.filter(
    complement => complement.ID_DM202 > 0,
  );

  const listDm202 = mandatory_complements
    .map(obj => {
      return {
        ID_DM202: obj.ID_DM202,
        SQ_GRUPO_ESCOLHA: obj.SQ_GRUPO_ESCOLHA,
      };
    })
    .filter((obj, index, self) => {
      const indexFound = self.findIndex(item => item.ID_DM202 === obj.ID_DM202);
      return index === indexFound;
    })
    .sort((a, b) => {
      if (a.SQ_GRUPO_ESCOLHA < b.SQ_GRUPO_ESCOLHA) {
        return -1;
      }
      return 0;
    });

  const groupByDm202 = complementList => {
    const result = [];

    complementList.forEach(object => {
      const id = object.ID_DM202;
      const existingGroup = result.find(group => group[0].ID_DM202 === id);

      if (existingGroup) {
        existingGroup.push(object);
      } else {
        result.push([object]);
      }
    });

    return result;
  };

  const mandatoryComplementsGroup = groupByDm202(mandatory_complements);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
  };

  useEffect(() => {
    const [sale_promotion, sale_amount, promotion_value] =
      checkPromotion(currentProduct);
    if (sale_promotion) setPromotional(true);
    if (!sale_amount && sale_promotion) setAddCart(false);
    setPromotionalPrice(promotion_value);
  }, [currentProduct]);

  return isLoading ? (
    <ContainerLoading>
      <Lottie options={defaultOptions} style={{ width: '100%', height: 400 }} />
      <h1>Carregando Produto...</h1>
    </ContainerLoading>
  ) : (
    <>
      <Container>
        <div id="container-image-product">
          <img
            src={url_product_img}
            alt="NoImage"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <ContainerContentProduct hasComplements={complementsCurrentProduct}>
          <InformationsProduct
            height={20}
            product={currentProduct}
            addCart={addCart}
            promotionalPrice={promotionalPrice}
          />
          {currentProduct.QT_SABOR > 0 && (
            <Tastes
              listTastes={tastesCurrentProductPost}
              taste={currentProduct.QT_SABOR}
            />
          )}
          <div id="complement_test">
            {add_complements.length > 0 &&
              group_values.map(val => {
                return (
                  <Complements
                    listComplements={add_complements.filter(complement => {
                      // NOTE: to keep older prjGerenciadorAutomacao
                      if (complement.QT_GRUPO_QUANTIDADE_COMBO === undefined) {
                        return complement.NR_GRUPO_QUANTIDADE === val;
                      }
                      return complement.QT_GRUPO_QUANTIDADE_COMBO === val;
                    })}
                    type="add"
                    counterProduct={counter}
                  />
                );
              })}
            {remove_complements.length > 0 &&
              group_values.map(val => {
                return (
                  <Complements
                    listComplements={remove_complements.filter(complement => {
                      // NOTE: to keep older prjGerenciadorAutomacao
                      if (complement.QT_GRUPO_QUANTIDADE_COMBO === undefined) {
                        return complement.NR_GRUPO_QUANTIDADE === val;
                      }
                      return complement.QT_GRUPO_QUANTIDADE_COMBO === val;
                    })}
                    type="rem"
                    counterProduct={counter}
                  />
                );
              })}
            {mandatoryComplementsGroup.length > 0 &&
              group_values.map(val => {
                return listDm202.map(val2 => {
                  return (
                    <Complements
                      listComplements={mandatory_complements
                        .filter(complement => {
                          // NOTE: to keep older prjGerenciadorAutomacao
                          if (
                            complement.QT_GRUPO_QUANTIDADE_COMBO === undefined
                          ) {
                            return complement.NR_GRUPO_QUANTIDADE === val;
                          }
                          return complement.QT_GRUPO_QUANTIDADE_COMBO === val;
                        })
                        .filter(
                          complementGroup =>
                            complementGroup.ID_DM202 === val2.ID_DM202,
                        )}
                      type="rem"
                      counterProduct={counter}
                    />
                  );
                });
              })}
          </div>
          {!(
            companyData.setMenu === 'S' &&
            companyData.setMenuTable === companyData.table
          ) && (
            <div id="comentary">
              <p>Algum comentário?</p>
              <textarea
                name="txtComments"
                id="txtComments"
                rows={5}
                onChange={val => {
                  setCommentValue(
                    String(val.target.value).toUpperCase(),
                    currentProduct.ID_DM004,
                  );
                }}
                placeholder="Ex: Tirar a cebola, maionese à parte etc."
              />
            </div>
          )}
        </ContainerContentProduct>
      </Container>
      <ContainerFooterAdd>
        <Link
          to="/"
          aria-label="return-to-home-page"
          onClick={() => {
            clearTastes();
            clearComplements();
            clearTasteComplements();
          }}
        >
          <IoIosArrowBack size={22} color={theme.palette.primary.main} />
        </Link>

        {addCart && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {companyData.setMenu === 'S' && companyData.setMenuTable === 0 ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            ) : (
              <>
                <ContainerCounter>
                  <button
                    type="button"
                    onClick={handleSubCount}
                    aria-label="sub-counter"
                  >
                    <MdRemove size={22} color={theme.palette.primary.main} />
                  </button>
                  <p>{counter}</p>
                  <button
                    type="button"
                    onClick={handleAddCount}
                    aria-label="add-counter"
                  >
                    <MdAdd size={22} color={theme.palette.primary.main} />
                  </button>
                </ContainerCounter>
                <Button
                  id="buttonAdd"
                  aria-label="add-to-cart"
                  onClick={() => {
                    handleAddProductToNewCart();
                  }}
                >
                  <p>Adicionar</p>
                  {promotional ? (
                    <p>{formatCurrencyBRL(currentPromotionalPrice)}</p>
                  ) : (
                    <p>{formatCurrencyBRL(totalPriceProduct)}</p>
                  )}
                </Button>
              </>
            )}
          </>
        )}
      </ContainerFooterAdd>
    </>
  );
}

export default ProductDetail;
