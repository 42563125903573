import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { clearStorage } from 'services/storage';

import Modal from 'components/Modal';

import {
  FiUser,
  FiChevronRight,
  FiEdit,
  FiServer,
  FiCornerUpLeft,
} from 'react-icons/fi';
import { theme } from '../../App';

import { Container, AnimationContainer, ContentCenter } from './styles';

function Profile(): React.ReactElement {
  const [showModalExit, setShowModalExit] = useState(false);

  return (
    <AnimationContainer>
      <Container>
        <ContentCenter>
          <div id="headerContent">
            <button type="button" aria-label="username">
              <FiUser size={30} color={theme.palette.primary.main} />
            </button>
            <p>Usuário Usuário Usuário Usuário Usuário</p>
          </div>
          <div id="listOptions">
            <button className="row" type="button">
              <Link to="/">
                <div>
                  <div>
                    <FiUser size={20} />
                  </div>
                  <p>Dados Pessoais</p>
                </div>
              </Link>
              <FiChevronRight size={25} color={theme.palette.primary.main} />
            </button>

            <button className="row" type="button">
              <Link to="/">
                <div>
                  <div>
                    <FiEdit size={20} />
                  </div>
                  <p>Editar Dados</p>
                </div>
              </Link>
              <FiChevronRight size={25} color={theme.palette.primary.main} />
            </button>

            <button className="row" type="button">
              <Link to="profile/3/last-orders">
                <div>
                  <div>
                    <FiServer size={20} />
                  </div>
                  <p>Últimos Pedidos</p>
                </div>
              </Link>
              <FiChevronRight size={25} color={theme.palette.primary.main} />
            </button>

            <button
              className="row"
              type="button"
              onClick={() => setShowModalExit(true)}
            >
              <div>
                <div>
                  <FiCornerUpLeft size={20} />
                </div>
                <p>Sair</p>
              </div>
              <FiChevronRight size={25} color={theme.palette.primary.main} />
            </button>
          </div>
        </ContentCenter>

        <Modal
          visible={showModalExit}
          setVisible={setShowModalExit}
          maxHeight={30}
          maxWidth={30}
        >
          <p className="text-modal-cpf">Deseja sair ?</p>

          <div className="buttons-modal-cpf">
            <button
              type="button"
              className="button-filled"
              onClick={() => setShowModalExit(false)}
            >
              Não
            </button>
            <button
              type="button"
              className="button-outline"
              onClick={() => {
                clearStorage();
                setShowModalExit(false);
              }}
            >
              Sim
            </button>
          </div>
        </Modal>
      </Container>
    </AnimationContainer>
  );
}

export default Profile;
