/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import Modal from 'components/TastesModal';

import { ComplementsProps, TastePost } from 'models';
import { useTastes } from 'hooks/useTastes';
import { useTasteComplements } from 'hooks/useTasteComplements';
import { useCallback, useEffect, useState } from 'react';
import SpecificApiLocalClient from 'services/api';
import { toast } from 'react-toastify';
import Complements from 'components/TasteComplements';
import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { Container, Description } from './styles';

interface TastesComponentProps {
  listTastes: TastePost[];
  taste?: number;
}

function Tastes({ listTastes, taste }) {
  const { selectedCheckbox } = useTastes();
  const { selectTasteUpdate } = useTasteComplements();
  const [showTasteModal, setshowTasteModal] = useState(false);
  const [showTasteModalDm004, setshowTasteModalDm004] = useState(0);
  const [selectedTasteModal, setSelectedTasteModal] = useState<TastePost>();
  const [complementsCurrentTaste, setComplementsCurrentTaste] = useState<
    ComplementsProps[]
  >([]);
  const [counter, setCounter] = useState(1);
  // const array_checkbox = Array(Number(listTastes.length)).fill(false);
  const [checked, setChecked] = useState([]);
  const [disabled, setDisabled] = useState([]);
  const api = SpecificApiLocalClient();
  const { companyData } = useInformationsClient();

  const getTasteComplements = (cd_item_sabor: number) => {
    api
      .get(`/fprg_GetComplementosByProdutoJSON/${cd_item_sabor}/0/`)
      .then(response => {
        const complementsResponse: ComplementsProps[] = response.data.result[0];
        if (complementsResponse.length > 0) {
          setComplementsCurrentTaste(complementsResponse);
          setshowTasteModal(true);
        }
      })
      .catch(() => {
        toast.error('Erro no carregamento dos Complementos do Sabor');
      });
  };

  useEffect(() => {
    setChecked(Array(Number(listTastes.length)).fill(false));
    setDisabled(Array(Number(listTastes.length)).fill(false));
  }, [listTastes, listTastes.length]);

  const handleChange = useCallback(
    (event, index: number, item) => {
      const newArrayCheckbox = [...checked];
      const checkboxLimit = Number(taste);
      setshowTasteModalDm004(item.cd_item_sabor);
      if (checkboxLimit > 0) {
        if (
          newArrayCheckbox.filter(val => val === true).length <= checkboxLimit
        ) {
          if (
            newArrayCheckbox.filter(val => val === true).length ===
            checkboxLimit
          ) {
            if (!event.target.checked) {
              selectedCheckbox(item, taste);
              selectTasteUpdate(item);
              setSelectedTasteModal(item);
              getTasteComplements(Number(item.cd_item_sabor));
            }
          } else {
            selectedCheckbox(item, taste);
            selectTasteUpdate(item);
            setSelectedTasteModal(item);
            if (event.target.checked) {
              getTasteComplements(Number(item.cd_item_sabor));
            }
          }
        }
        if (event.target.checked) {
          if (
            newArrayCheckbox.filter(val => val === true).length < checkboxLimit
          ) {
            newArrayCheckbox[index] = event.target.checked;
            if (
              newArrayCheckbox.filter(val => val === true).length ===
              checkboxLimit
            ) {
              try {
                const div = document.getElementById('1');
                div.scrollIntoView({ behavior: 'smooth' });
              } catch {
                // Ignore scroll error
              }
              const newDisableArray = [];
              newArrayCheckbox.forEach(e => {
                newDisableArray.push(!e);
              });
              setDisabled(newDisableArray);
            }
          }
        } else {
          setDisabled(Array(Number(listTastes.length)).fill(false));
          newArrayCheckbox[index] = event.target.checked;
        }
      } else {
        newArrayCheckbox[index] = event.target.checked;
        selectedCheckbox(item, taste);
        selectTasteUpdate(item);
        setSelectedTasteModal(item);
        if (event.target.checked) {
          getTasteComplements(Number(item.cd_item_sabor));
        }
      }
      setChecked(newArrayCheckbox);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checked],
  );

  const mandatoryComplements = complementsCurrentTaste.filter(
    complement => complement.ID_DM202 > 0,
  );
  const group_values = mandatoryComplements
    .map(item => {
      return item.ID_DM202;
    })
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
    .sort();

  return (
    <>
      <Modal
        visible={showTasteModal}
        setVisible={setshowTasteModal}
        idDM004={showTasteModalDm004}
        maxHeight={50}
        maxWidth={50}
      >
        <p style={{ marginBottom: '15px' }}>
          {selectedTasteModal !== undefined ? selectedTasteModal.ds_item : ''}
        </p>
        {complementsCurrentTaste.length > 0 && (
          <>
            <Complements
              listComplements={complementsCurrentTaste.filter(complement => {
                return (
                  complement.IN_ADICIONAL_REMOCAO !== 'A' &&
                  complement.ID_DM202 === 0
                );
              })}
              type="add"
              counterProduct={counter}
            />
            <Complements
              listComplements={complementsCurrentTaste.filter(complement => {
                return (
                  complement.IN_ADICIONAL_REMOCAO === 'R' &&
                  complement.ID_DM202 === 0
                );
              })}
              type="rem"
              counterProduct={counter}
            />
            {mandatoryComplements.length > 0 &&
              group_values.map(val => {
                return (
                  <Complements
                    listComplements={mandatoryComplements.filter(
                      complement => complement.ID_DM202 === val,
                    )}
                    type="rem"
                    counterProduct={counter}
                  />
                );
              })}
          </>
        )}
      </Modal>
      {listTastes.length > 0 && (
        <Container style={{ width: '100%' }}>
          <div id="title-complement">
            {companyData.setMenu === 'S' &&
            companyData.setMenuTable === companyData.table ? (
              <div>
                <p>{taste === 1 ? `Sabor:` : ''}</p>
                <p>{taste > 1 ? `Até ${taste} sabores:` : ''}</p>
              </div>
            ) : (
              <div>
                <p>{taste === 1 ? `Selecione o sabor:` : ''}</p>
                <p>{taste > 1 ? `Selecione até ${taste} sabores:` : ''}</p>
              </div>
            )}
          </div>
          {listTastes.map((item: TastePost, index) => {
            return (
              <ul key={item.ds_item}>
                <li>
                  <Description>
                    <label htmlFor={item.ds_item}>{item.ds_item}</label>
                    {item.DS_DESCRICAO_PRODUTO && (
                      <p
                        style={{
                          color: '#3c3c3c',
                          fontSize: '0.9rem',
                          marginBottom: '13px',
                        }}
                      >
                        {item.DS_DESCRICAO_PRODUTO}
                      </p>
                    )}
                    {item.VL_ITEM_SABOR !== 0 && (
                      <p className="complement-value">
                        {formatCurrencyBRL(item.VL_ITEM_SABOR)}
                      </p>
                    )}
                  </Description>
                  {!(
                    companyData.setMenu === 'S' &&
                    companyData.setMenuTable === companyData.table
                  ) && (
                    <Checkbox
                      id={item.ds_item}
                      icon={<RadioButtonUncheckedRoundedIcon />}
                      checkedIcon={<RadioButtonCheckedRoundedIcon />}
                      color="primary"
                      style={{
                        marginRight: '2rem',
                      }}
                      disabled={disabled[index]}
                      inputProps={{
                        'aria-label': `checkbox-${item.ds_item}`,
                      }}
                      onChange={event => {
                        handleChange(event, index, item);
                      }}
                      classes={{ root: 'increase-size-check' }}
                    />
                  )}
                </li>
              </ul>
            );
          })}
        </Container>
      )}
    </>
  );
}

export default Tastes;
