// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import Modal from 'components/Modal';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { useCallback, useState } from 'react';
import { Container, AnimationContainer, ContentCenter } from './styles';

function NewCommand() {
  const { updateRoute } = useRoutes();
  const { companyData } = useInformationsClient();
  const [showModalNote, setShowModalNote] = useState(false);
  const history = useHistory();
  const [valueInput, setValueInput] = useState('');
  const handleInsertCommand = useCallback((val: string) => {
    // #TODO: Check API from automação to merge commands
  }, []);

  return (
    <AnimationContainer>
      <Container>
        <ContentCenter>
          <div id="headerContent">
            <p>Inserir comanda</p>
          </div>
          <div id="divCommand">
            <input
              type="number"
              className="inputCommand"
              placeholder="Número da comanda"
              onChange={e => setValueInput(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div id="footerContent">
            <button
              type="button"
              className="button-filled button-footer"
              onClick={() => {
                setShowModalNote(true);
              }}
            >
              <p>Adicionar comanda</p>
            </button>
          </div>
          <Modal
            visible={showModalNote}
            setVisible={setShowModalNote}
            maxHeight={30}
            maxWidth={20}
          >
            <p className="text-modal">Deseja adiconar a comanda?</p>

            <div className="buttons-modal">
              <button
                type="button"
                className="button-outline"
                onClick={() => {
                  handleInsertCommand(valueInput);
                  updateRoute('/command');
                  history.push('/command');
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="button-filled"
                onClick={() => {
                  updateRoute('/');
                  history.push('/');
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </ContentCenter>
      </Container>
    </AnimationContainer>
  );
}

export default NewCommand;
