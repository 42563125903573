import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';

import { useTasteComplements } from 'hooks/useTasteComplements';

import CounterTasteComplements from 'components/CounterTasteComplements';

import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';

import { ComplementsProps } from 'models';

import { Container, Description } from './styles';

interface TasteComplementsComponentProps {
  type: 'add' | 'rem';
  listComplements: ComplementsProps[];
  counterProduct?: number;
  cd_item_sabor?: number;
}

function Complements({ type, listComplements, counterProduct }) {
  const { selectTasteCheckboxs } = useTasteComplements();

  const checkLocalStorage = (cd_item_complemento: number): boolean => {
    let tasteComplementsLocalStorage = [];
    try {
      tasteComplementsLocalStorage = JSON.parse(
        localStorage.getItem('tasteComplements'),
      );
      if (tasteComplementsLocalStorage.length !== 0) {
        return tasteComplementsLocalStorage.some(
          val => val.ID_DM026 === cd_item_complemento,
        );
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <Container>
      {listComplements.length > 0 && (
        <>
          <div id="title-complement">
            <div>
              <p>
                {type === 'add' && listComplements[0].ID_DM202 === 0
                  ? 'Adicionar:'
                  : ''}
              </p>
              <p>
                {type === 'rem' && listComplements[0].ID_DM202 === 0
                  ? 'Remover:'
                  : ''}
              </p>
              <p>
                {listComplements[0].ID_DM202 > 0
                  ? listComplements[0].DS_GRUPO_ESCOLHA
                  : ''}
              </p>
            </div>
          </div>
          {listComplements.map((item: ComplementsProps) => {
            return (
              <ul key={item.DS_ITEM}>
                <li>
                  <Description>
                    <label htmlFor={item.DS_ITEM}>{item.DS_ITEM}</label>
                    {item.VL_VENDA !== 0 && (
                      <p className="complement-value">
                        + {formatCurrencyBRL(item.VL_VENDA)}
                      </p>
                    )}
                  </Description>
                  {item.IN_SOLICITA_QUANTIDADE === 'S' ? (
                    <CounterTasteComplements
                      complement={item}
                      counter={counterProduct || 1}
                    />
                  ) : (
                    <Checkbox
                      id={item.DS_ITEM}
                      icon={<RadioButtonUncheckedRoundedIcon />}
                      checkedIcon={<RadioButtonCheckedRoundedIcon />}
                      color="primary"
                      checked={checkLocalStorage(item.ID_DM026)}
                      style={{
                        marginRight: '2rem',
                      }}
                      inputProps={{
                        'aria-label': `checkbox-${item.DS_ITEM}`,
                      }}
                      onClick={() => {
                        selectTasteCheckboxs(item, type);
                      }}
                      classes={{ root: 'increase-size-check' }}
                    />
                  )}
                </li>
              </ul>
            );
          })}
        </>
      )}
    </Container>
  );
}

export default Complements;
