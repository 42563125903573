import React from 'react';
import { Link } from 'react-router-dom';

import ProductHorizontal from 'components/ProductHorizontal';
import { ProductsProps } from 'models/index';
import { Container } from './styles';

interface CategoryProps {
  title: string;
  products: Array<ProductsProps>;
  search: boolean;
}

function Category({ title, products, search }) {
  return (
    <Container id={title} showSearch={search}>
      {products.length > 0 && (
        <>
          <p className="title">{title}</p>
          <div className="grid-items">
            {products.map(product => {
              return (
                <Link
                  to={`/products/${product.ID_DM004}`}
                  style={{ textDecoration: 'none' }}
                  key={product.CD_ITEM}
                >
                  <ProductHorizontal product={product} />
                </Link>
              );
            })}
          </div>
        </>
      )}
    </Container>
  );
}

export default Category;
