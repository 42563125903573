import styled, { keyframes } from 'styled-components';

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: calc(100vh - 10rem);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 4rem;

  // PEQUENO
  @media screen and (max-width: 1060px) {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  // GRANDE
  @media screen and (min-width: 1060px) {
    width: 50%;
    height: 95%;
    max-height: 95%;

    box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
    border: 1px solid var(--line-color);
    border-radius: 8px;

    background-color: #fff;
  }

  #headerContent {
    width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;
    position: sticky;
    top: 0;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1rem;
      color: var(--primary-color);
    }
  }

  #listProducts {
    min-height: calc(100% - 15rem);
    max-height: calc(100% - 7.5rem);

    padding: 2rem 0;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    #title-modal {
      font-size: 1.25rem;
      font-weight: 100;
    }

    #description-modal {
      font-size: 1rem;
      color: #9d9d9d;
      margin-top: 1.75rem;
    }
  }

  #footerContent {
    margin: auto;
    width: 82%;
    min-height: 3.75rem;
    max-height: 3.75rem;
    position: sticky;
    bottom: 0;

    border-top: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: flex;
    flex-flow: column;
    justify-content: space-around;
    grid-template-columns: 45% 1fr;
    justify-items: center;
    align-items: center;

    .divKeepShopping {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4rem;

      /* o ultimo elemento deve ter um margin bottom */
    }

    .divButton {
      width: 100%;
      margin-top: 2%;
    }

    .button-keepShoping {
      width: 100% !important;
      border: 1px solid var(--primary-color);
      border-radius: 4px;
      background-color: var(--secondary-color);
      color: #6c6c6c;
    }

    .placeOrder {
      width: 100% !important;
      border: none;
      border-radius: 4px;
      background-color: var(--primary-color);
      color: #fff;
    }

    button.button-footer {
      width: 48%;
      height: 3rem;
      border-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      font-size: 1rem;
    }

    .text-modal {
      font-size: 1.2rem;
    }

    .text-modal-delete {
      margin-top: 2rem;
      font-size: 1.25rem;
      font-weight: 100;
      text-transform: capitalize;
    }

    .buttons-modal-cpf {
      width: 100%;

      display: flex;

      justify-content: space-between;
      align-items: end;

      margin-top: 4rem;

      button {
        width: 45%;
        height: 2.5rem;

        font-size: 1rem;
      }
    }
    .buttons-modal-delivery {
      width: 100%;
      display: flex;

      justify-content: space-between;
      align-items: end;

      margin-top: 2.25rem;

      button {
        width: 100%;
        height: 2.5rem;
        background-color: #f47321;
        color: #fff;
        font-size: 1rem;
      }
    }

    .address-modal-delivery {
      width: 100%;

      align-items: end;
    }
    .address-modal-delivery-code {
      width: 42%;
      align-items: center;
      text-align: center;
      height: 0.5rem;
      margin-top: 1rem;
    }

    .buttons-modal-address {
      width: 100%;
      display: flex;

      justify-content: space-between;
      align-items: end;

      margin-top: 1.25rem;

      button {
        width: 100%;
        height: 2.5rem;
        background-color: #fdf7f7;
        color: #000;
        font-size: 1rem;
      }
    }
    .divDetailsOrder {
      color: #f47321;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      padding-top: 20px;
      width: 90%;
    }

    .MuiSelect-select {
      width: 100% !important;
      text-align: left;
    }

    // END STYLES MODAL - CPF
  }
`;

export const ContainerLoading = styled.div`
  height: calc(100vh - 7.5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
  }
`;
