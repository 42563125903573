/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface UserTelephoneProviderProps {
  children: ReactNode;
}

interface UserTelephoneContextData {
  formatedNumber: number;
  unformatedNumber: string;
  accepted: boolean;
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  getTelephoneNumber: (number: any) => void;
}

const UserTelephoneContext = createContext<UserTelephoneContextData>(
  {} as UserTelephoneContextData,
);

export function UserTelephoneProvider({
  children,
}: UserTelephoneProviderProps) {
  const [accepted, setAccepted] = useState(false);
  const [unformatedNumber, setUnformatedNumber] = useState('');
  const [formatedNumber, setFormatedNumber] = useState(0);

  const getTelephoneNumber = useCallback((number: number) => {
    setFormatedNumber(number);
    const unformatedNumberString = String(number)
      .replace(/\s+/g, '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');
    setUnformatedNumber(unformatedNumberString);
    setAccepted(true);
  }, []);

  const value = useMemo(
    () => ({
      formatedNumber,
      unformatedNumber,
      accepted,
      setAccepted,
      getTelephoneNumber,
    }),
    [
      formatedNumber,
      unformatedNumber,
      accepted,
      setAccepted,
      getTelephoneNumber,
    ],
  );

  return (
    <UserTelephoneContext.Provider value={value}>
      {children}
    </UserTelephoneContext.Provider>
  );
}

export const useUserTelephone = () => {
  const context = useContext(UserTelephoneContext);

  return context;
};
