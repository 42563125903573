import React, { useState } from 'react';
import { ProductsCartProps } from 'models';
import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import produtoSemImagem from 'assets/produtosemimagem.jpg';
import Modal from 'components/Modal';
import { FiTrash2 } from 'react-icons/fi';
import { Container } from './styles';
import { useInformationsClient } from '../../hooks/useInformationsClient';

interface ProductCartProps {
  props: ProductsCartProps;
  getIdToDelete(id: number): void;
}

function ProductCart({ props, getIdToDelete }) {
  const { companyData } = useInformationsClient();

  const [showModalDescription, setShowModalDescription] = useState(false);
  const {
    subtotal,
    qtd,
    DS_ITEM,
    ID_DM004,
    DS_DESCRICAO_PRODUTO,
    timestamp,
    complementos,
    sabores,
  } = props;

  const url_product_img = `https://datamais.com.br/img-autoatendimento/${companyData.companyCode}/I${ID_DM004}.jpg`;

  return (
    <Container>
      <img src={url_product_img} alt="NoImage" />
      <div id="informationsProduct">
        <p id="title-product">{DS_ITEM}</p>
        <button
          type="button"
          onClick={() => setShowModalDescription(true)}
          className="more-informations"
        >
          <p>Mais Informações</p>
        </button>
        <p id="price-product">{formatCurrencyBRL(Number(subtotal))}</p>
      </div>
      <div id="actionsArea">
        <button
          type="button"
          onClick={() => {
            getIdToDelete(timestamp);
          }}
          aria-label="remove-product-button"
        >
          <FiTrash2 size={15} color="#f00" aria-hidden="true" />
        </button>
        <p id="qtde-product">Qtd. {qtd}</p>
      </div>
      <Modal
        visible={showModalDescription}
        setVisible={setShowModalDescription}
        maxHeight={300}
        maxWidth={30}
      >
        <br />
        <h2>Informações do item</h2>
        <br />
        <p id="text-modal">Descrição: {DS_DESCRICAO_PRODUTO}</p>
        {sabores.length > 0 && (
          <>
            <h3>Sabores</h3>
            {sabores.map(val => {
              return (
                <>
                  <p>{val.ds_item}</p>
                  {val.complementos.length > 0 &&
                    val.complementos.map(val2 => {
                      return <p>&nbsp;&nbsp;&nbsp;&nbsp;-{val2.DS_ITEM}</p>;
                    })}
                </>
              );
            })}
          </>
        )}
        {complementos.length > 0 && (
          <>
            <h3>Complementos</h3>
            {complementos.map(val => {
              return <p>{val.ds_item}</p>;
            })}
          </>
        )}
        <br />
        <br />
      </Modal>
    </Container>
  );
}

export default ProductCart;
