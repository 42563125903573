/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface RoutesProviderProps {
  children: ReactNode;
}

interface RoutesContextData {
  currentRoute: string;
  updateRoute: (route: string) => void;
}

const RoutesContext = createContext<RoutesContextData>({} as RoutesContextData);

export function RoutesProvider({ children }: RoutesProviderProps) {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  const updateRoute = (route: string) => {
    setCurrentRoute(route);
  };

  const value = useMemo(() => ({ currentRoute, updateRoute }), [currentRoute]);

  return (
    <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>
  );
}

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  return context;
};
