import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { StyledSendReceiptToWhatsapp } from './styles';
import { theme } from '../../../../App';

export function SendReceiptToWhatsapp() {
  const { companyData } = useInformationsClient();
  const { setWhatsAppNumber } = companyData;

  function formatWhatsappNumber(whatsappNumber: string) {
    return whatsappNumber.replace(/[^0-9]/g, '');
  }

  function openWhatsappLink(whatsappNumber: string) {
    const formattedWhatsappNumber = formatWhatsappNumber(whatsappNumber);
    const url = `https://wa.me/${formattedWhatsappNumber}`;
    window.open(url, '_blank');
  }

  return (
    <StyledSendReceiptToWhatsapp theme={theme}>
      <button
        color={theme.palette.primary.main}
        type="button"
        className="whatsapp-button"
        onClick={() => openWhatsappLink(setWhatsAppNumber)}
      >
        Enviar Comprovante no Whatsapp
        <FaWhatsapp />
      </button>
    </StyledSendReceiptToWhatsapp>
  );
}
