import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Routes from 'routes';

import { ComplementsProvider } from 'hooks/useComplements';
import { TasteComplementsProvider } from 'hooks/useTasteComplements';
import { TastesProvider } from 'hooks/useTastes';
import { CommentProvider } from 'hooks/useComments';
import { CartProvider } from 'hooks/useCart';
import { UserTelephoneProvider } from 'hooks/useUserTelephone';
import { RoutesProvider } from 'hooks/useRoutes';
import { InformationsClientProvider } from 'hooks/useInformationsClient';
import { DateLoginProvider } from 'hooks/useDateLogin';

import { Bounce, ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';

export const theme = createTheme({
  palette: {
    primary: { main: '#F47321' },
    secondary: { main: '#FDF7F7' },
    error: { main: '#f44336' },
    info: { main: '#2196f3' },
    success: { main: '#2A7925' },

    contrastThreshold: 3,

    tonalOffset: 0.2,
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  useEffect(() => {
    const saveCurrentDate = () => {
      const currentDate = Math.floor(Date.now() / 1000); // Data atual em formato Unix
      localStorage.setItem('lastVisitDate', currentDate.toString());
    };

    const clearCacheIfNeeded = () => {
      const lastVisitDate = localStorage.getItem('lastVisitDate');
      if (lastVisitDate) {
        const lastVisit = parseInt(lastVisitDate, 10);
        const currentDate = Math.floor(Date.now() / 1000); // Data atual em formato Unix
        const diffTime = currentDate - lastVisit;
        const diffDays = diffTime / (60 * 60 * 24); // Diferença em dias
        if (diffDays > 15) {
          caches
            .keys()
            .then(names => {
              // eslint-disable-next-line no-restricted-syntax
              for (const name of names) caches.delete(name);
            })
            .then(() => {
              window.location.reload(); // Força o recarregamento completo da página
            });
          saveCurrentDate();
        }
      } else {
        saveCurrentDate();
      }
    };

    clearCacheIfNeeded();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename="/">
        <InformationsClientProvider>
          <UserTelephoneProvider>
            <DateLoginProvider>
              <ComplementsProvider>
                <TastesProvider>
                  <TasteComplementsProvider>
                    <CartProvider>
                      <CommentProvider>
                        <RoutesProvider>
                          <Routes />
                          <Footer />
                          <GlobalStyle />
                          <ToastContainer
                            position="top-right"
                            transition={Bounce}
                            draggable={false}
                            role="alert"
                            autoClose={3000}
                          />
                        </RoutesProvider>
                      </CommentProvider>
                    </CartProvider>
                  </TasteComplementsProvider>
                </TastesProvider>
              </ComplementsProvider>
            </DateLoginProvider>
          </UserTelephoneProvider>
        </InformationsClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
